import Head from "next/head";

import React from "react";
import type { GetStaticProps, GetStaticPaths } from "next";

import Script from "next/script";

import {
  SkinProvider,
  Skin,
  riotProductLionBaseChroma,
  leagueClientBaseTheme,
  TFTChroma,
  LoLChroma,
  ValorantChroma,
  WildRiftChroma,
  AnalyticsProvider,
  ArcaneChroma,
} from "@riotgames/blades-ui";

import type { Hreflang } from "../utils/api/types/pages";

import { sanitizeString } from "../utils/sanitize";

import { getChannelId } from "../utils/channelId";

import type { PageProps, PathProps } from "../utils/api/types/pages";

import BladesRenderer from "../components/BladesRenderer";

import { BladesAPI } from "../utils/api";
import { RenderAvailabilityMiniBlade } from "../components/RenderAvailabilityMiniBlade";
import { WarningsBlade } from "../components/WarningsBlade";
import { checkLoL2TfTRedirect } from "../utils/lol-redirects";
import { getBackend } from "../utils/api/backend";
import config from "../utils/env";

const SUPPORTED_CHROMAS: { [key: string]: Skin } = {
  ArcaneChroma,
  LoLChroma,
  TFTChroma,
  ValorantChroma,
  WildRiftChroma,
  riotProductLionBaseChroma,
  leagueClientBaseTheme,
};

export default function Page({
  page,
  environment,
  datadogData,
  shouldRenderAvailability,
}: PageProps) {
  const skin =
    SUPPORTED_CHROMAS[page.theme.id as keyof typeof SUPPORTED_CHROMAS];

  if (!skin) {
    throw new Error(`Unsupported theme: ${page.theme.id}`);
  }

  const sanitizedGTMId =
    page?.gtmContainerId && sanitizeString(page.gtmContainerId);

  return (
    <div>
      <Head>
        <title>{page.title}</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1"
        ></meta>
        <meta name="description" content={page.description} />
        {page.hreflangs?.map((herflang: Hreflang) => (
          <link
            key={herflang.locale}
            rel="alternate"
            href={herflang.url}
            hrefLang={herflang.locale}
          />
        ))}
        <meta property="og:url" content={page.url} />
        <meta property="og:title" content={page.title} />
        <meta property="og:description" content={page.description} />
        <meta
          property="og:image"
          content={`${page.metaImage?.url}?auto=webp&disable=upscale&height=630&width=1200&crop=1200:630`}
        />
        <meta property="og:locale" content={page.locale} />
        {page.favicon?.icon?.map((icon, index) => (
          <link
            rel="icon"
            href={icon.icon}
            sizes={icon.size}
            key={`icon-${index}`}
          />
        ))}
        {page.favicon?.appleTouch?.map((appleTouch, index) => (
          <link
            rel="apple-touch-icon"
            href={appleTouch.icon}
            sizes={appleTouch.size}
            key={`apple-touch-icon-${index}`}
          />
        ))}
        {page.favicon?.shortcut?.map((shortcut, index) => (
          <link
            rel="shortcut icon"
            href={shortcut.icon}
            sizes={shortcut.size}
            key={`shortcut-icon-${index}`}
          />
        ))}

        {/* DO NOT ADD ANY SCRIPTS ABOVE OSANO OR GOOGLE CONSENT. THEY MUST ALWAYS LOAD FIRST. */}
        {/* Google Consent Script */}
        {/* Note: Using script instead of Script because there are existing bugs regarding ordering of scripts in next.js */}
        <script
          id="google-consent-script"
          dangerouslySetInnerHTML={{
            __html: `try {
              window.dataLayer = window.dataLayer ||[];
              function gtag(){dataLayer.push(arguments);}
              gtag('consent','default',{
                'ad_storage':'denied',
                'analytics_storage':'denied',
                'ad_user_data':'denied',
                'ad_personalization':'denied',
                'wait_for_update': 500
              });
              gtag("set", "ads_data_redaction", true);
            } catch(e) {}`,
          }}
        />
        {/* OSANO */}
        <script
          id="osano-script"
          src={`https://cmp.osano.com/16BZ95S4qp9Kl2gUA/${page.osanoPolicyId}/osano.js`}
        />
        {/* Google Tag Manager */}
        <script
          id="google-tag-manager"
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${sanitizedGTMId}');`,
          }}
        />
      </Head>

      {/* dd-browser-agent */}
      {datadogData?.serviceName &&
        datadogData?.clientToken &&
        datadogData?.applicationId && (
          <Script
            id="dd-browser-agent"
            dangerouslySetInnerHTML={{
              __html: `(function(h,o,u,n,d) {
              h=h[d]=h[d]||{q:[],onReady:function(c){h.q.push(c)}}
              d=o.createElement(u);d.async=1;d.src=n
              n=o.getElementsByTagName(u)[0];n.parentNode.insertBefore(d,n)
            })(window,document,'script','https://www.datadoghq-browser-agent.com/us1/v5/datadog-rum.js','DD_RUM')
            window.DD_RUM.onReady(function() {
              window.DD_RUM.init({
                clientToken: '${datadogData.clientToken}',
                applicationId: '${datadogData.applicationId}',
                site: 'datadoghq.com',
                service: '${datadogData.serviceName}',
                env: '${environment}',
                sessionSampleRate: 20,
                sessionReplaySampleRate: 3,
                trackUserInteractions: true,
                trackResources: true,
                trackLongTasks: true,
                defaultPrivacyLevel: 'mask-user-input',
              });
            })`,
            }}
          />
        )}

      <main>
        {sanitizedGTMId && (
          <noscript
            dangerouslySetInnerHTML={{
              __html: `<iframe
              src='https://www.googletagmanager.com/ns.html?id=${sanitizedGTMId}'
              height="0"
              width="0"
              style="display: none; visibility: hidden;"></iframe>`,
            }}
          />
        )}

        <SkinProvider
          skin={skin}
          locale={page.locale}
          dir={page.locale === "ar-ae" ? "rtl" : "ltr"}
          environment={environment}
        >
          <AnalyticsProvider
            page={{
              id: page.translationId,
              url: page.url,
              locale: page.locale || "en-us",
              // TODO: version should come from the API or env variable
              version: "2",
            }}
          >
            <BladesRenderer
              blades={page.blades}
              locale={page.locale || "en-us"}
              environment={environment}
            />
            {shouldRenderAvailability && (
              <RenderAvailabilityMiniBlade locale={page.locale} />
            )}
            {page.warnings?.length ? (
              <WarningsBlade
                environment={environment}
                warnings={page.warnings}
              />
            ) : null}
          </AnalyticsProvider>
        </SkinProvider>
      </main>
    </div>
  );
}

export const getStaticPaths: GetStaticPaths = async () => {
  return {
    paths: [], // Empty array because you don't want to pre-render any pages at build time
    fallback: "blocking", // Generate pages on-the-fly when a request comes in
  };
};

export const getStaticProps: GetStaticProps<PageProps, PathProps> = async ({
  params,
  locales,
  locale = "en-us",
}) => {
  try {
    if (!params) throw new Error("No path parameters found");
    if (!locales) throw new Error("No locales were set");
    // const { backend, channel, path: pathArray } = params;
    const backend = getBackend();
    const channelId = getChannelId();
    const environment = config.SITE_ENV;
    const { pathArray = [] } = params;

    /**
     * Disable news pages if the environment variable is set to true.
     * Used to disable news pages for certain sites.
     */
    if (config.DISABLE_NEWS_PAGES && pathArray?.[0] === "news") {
      return {
        notFound: true,
        revalidate: 120, // In seconds
      };
    }

    const path =
      pathArray && Array.isArray(pathArray)
        ? pathArray.join("/")
        : pathArray || "index";

    const shouldRedirect = checkLoL2TfTRedirect({ channelId, locale, path });
    if (shouldRedirect) {
      return {
        redirect: {
          destination: shouldRedirect,
          permanent: true,
        },
      };
    }
    console.log("fetching page data", { path, channelId, environment, locale });
    const pageData = await BladesAPI(
      {
        environment,
        channelId,
        locale,
      },
      backend
    ).fetchPage(path);
    if (!pageData) {
      console.log("No page data found:", pageData, { params, locales });
      return {
        notFound: true,
        revalidate: 120, // In seconds
      };
    }
    const shouldRenderAvailability = config.SHOULD_RENDER_AVAILABILITY;
    console.log("Page data found:", { params, locales });
    return {
      props: {
        page: pageData,
        environment,
        shouldRenderAvailability,
        datadogData: {
          clientToken: config.DATADOG_CLIENT_TOKEN,
          applicationId: config.DATADOG_APP_ID,
          serviceName: config.DATADOG_SERVICE_NAME,
        },
      },
      // Only trigger rebuilds after 2 minutes
      revalidate: config.DEFAULT_ISR_PAGE_TTL, // In seconds
    };
  } catch (error) {
    console.error(`ERROR: ${error}`, { params, locales });
    throw error;
    // return {
    //   notFound: true,
    //   revalidate: 120, // In seconds
    // };
  }
};
